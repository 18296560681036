<template>
  <el-main class="rh-medtable">
    <h1>Parcours clinique</h1>
    <app-table
      tablenom="medparcourscliniques"
      :cols="colonnes"
      :items="parcourscliniques"
      :loadstatus="parcourscliniquesLoadStatus"
      :deletestatus="parcourscliniqueDeleteStatus"
      :formroutes="pcFormRoutes"
      :actions="pcActions"
      :ismeddetail="true"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: this.$route.params.idmed, // récupère le id du médecin dans l'url
      pcFormRoutes: {
        edit: {
          route: "med_parcourscliniques_edit",
          params: { idmed: "id_med", idpc: "id" },
        },
        create: {
          route: "med_parcourscliniques_create",
          params: { idmed: this.$route.params.idmed },
        },
      },
    };
  },
  computed: {
    parcourscliniquesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.pc.parcourscliniquesLoadStatus;
    },
    parcourscliniqueDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.pc.parcourscliniqueDeleteStatus;
    },
    parcourscliniques() {
      // n'affiche que les parcourscliniques du médecin sélectionné
      return this.$store.state.pc.parcourscliniques.filter(
        (c) => c.id_med == this.idMed
      );
    },
    colonnes() {
      // n'affiche pas la colonne 'noms des médecins'
      return this.$store.state.pc.colonnes.filter(
        (col) => col.prop != "medecin"
      );
    },
    pcActions() {
      return this.$store.state.pc.actions;
    },
  },
};
</script>
